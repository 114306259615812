<template>
  <div class="modify-mobile">
    <el-form :inline-message="true" :model="ruleForm" :rules="rules" label-width="110px"
      ref="ruleForm">
      <div class="form-info">
        <div class="info">
          <el-form-item label="新手机号" prop="mobile" class="info">
            <el-input class="verifyCode" v-model="ruleForm.mobile"></el-input>
          </el-form-item>

          <getVerificationCode :mobile="ruleForm.mobile" class="modify-mobile__btn--code" :validFunction="validFunction"/>
        </div>

        <el-form-item label="验证码" prop="verifyCode">
          <el-input class="verifyCode" v-model="ruleForm.verifyCode"  type="text" maxlength="4"></el-input>
        </el-form-item>

      </div>

      <div class="btn-wrap">
        <el-button :loading="saveLoading" class="modify-mobile__btn--before modify-mobile__btn"
          type="primary" @click="toBefore">上一步</el-button>
        <el-button :loading="saveLoading" @click="submitForm('ruleForm')"
          class="modify-mobile__btn--save modify-mobile__btn" type="primary">保存</el-button>
      </div>

    </el-form>
    <el-dialog :before-close="toLogin" :close-on-click-modal="false" :visible.sync="showSuccess"
      width="530px">
      <div class="success">
        <i class="el-icon-circle-check"></i>
        <div class="success-detail">手机号更换成功</div>
        <div class="mobile">新手机号：{{ ruleForm.mobile }}</div>
        <div class="tips">手机号码更换完成后，需要重新进行登录</div>
      </div>
      <div class="dialog__footer" slot="footer" style="text-align: center;">
        <slot name="sure">
          <el-button @click="toLogin" type="primary" class="dialog__footer--btn">重新登录</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';
import { remove } from '@/utils/removeLocalStorage';
import getVerificationCode from '@/components/getVerificationCode';
import { distributorChangeMobileForMerchant, distributorChangeMobileValidNewMobile } from '@/api/login/modify-mobile';
export default {
  name: 'modify-mobile',
  components: { getVerificationCode },
  data() {
    return {
      showSuccess: false,
      verifyType: 'MOBILE', // 验证类型 MOBILE  :手机号 EMAIL  ：邮件
      ruleForm: {
        mobile: '',
        verifyCode: ''
      },
      rules: {
        verifyCode: [{ required: true, message: '请输入手机验证码', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
      },
      saveLoading: false,
    };
  },
  created() {
    this.verifyType = this.$route.query && this.$route.query.verifyType ? this.$route.query.verifyType : '';
   },
  methods: {
    ...mapActions(['LogOut']),
    ...mapMutations('chat', ['CUSTOMER_DOT_SHOW']),
    ...mapActions('chat', ['shutDown']),
    validFunction() {
      return new Promise((resolve, reject) => {
          distributorChangeMobileValidNewMobile({
            newMobile: this.ruleForm.mobile,
          }).then(res => {
            if (res && res.code === '0') {
              resolve({ code: '0' });
              return;
            }
            reject(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    toLogin() {
      this.LogOut().then(() => {
        setTimeout(() => {
          this.CUSTOMER_DOT_SHOW(false);
          this.shutDown();
        }, 100);
        this.$router.replace('/syoung/login?redirect=/');
        remove();
      });
    },
    toBefore() {
      this.$router.replace(`/syoung/information/modify-mobile`);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (!this.ruleForm.mobile || this.ruleForm.mobile.trim().length !== 11) {
            this.$message.error('请正确输入手机号');
            return;
          }
          if (!this.ruleForm.verifyCode || this.ruleForm.verifyCode.trim().length === 0) {
            this.$message.error('请输入验证码');
            return;
          }
          if (!this.verifyType) {
            this.$message.error('请返回上一页，确认验证类型');
            return;
          }
          const data = {
            newMobile: this.ruleForm.mobile.trim(),
            newMobileVerifyCode: this.ruleForm.verifyCode.trim(),
            verifyType: this.verifyType,
          };
          if (this.verifyType === 'MOBILE') {
            data.oldMobileVerifyCode = this.oldMobileVerifyCode;
          }
          const ajaxData = await distributorChangeMobileForMerchant(data);
          if (ajaxData.success) {
            this.showSuccess = true;
          } else {
            this.$message.error(ajaxData.msg);
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.modify-mobile {
  padding-top: 136px;
  padding-bottom:350px;

  .form-info {
    width: 350px;
    margin: 0 auto;
  }

  .info {
    font-size: 15px;
    margin: 30px 0;
    font-weight: 600;
    color: #333333;
    line-height: 21px;
    position: relative;

    .modify-mobile__btn--code {
      color: #AB0033;
      cursor: pointer;
      position: absolute;
      width: 150px;
      height: 28px;
      line-height: 28px;
      top: 0;
      right: -150px;
    }
  }

  .btn-wrap {
    display: flex;
    justify-content: center;
    margin-left: 100px;
  }

  &__btn {
    font-size: 18px;
    margin-top: 100px;
    width: 180px;
    height: 40px;
    border-radius: 2px;
  }

  &__btn--before {
    color: #BD002F;
    border: 1px solid #BD002F;
    background: #fff;
  }

  &__btn--save {
    color: #fff;
    border: 1px solid #BD002F;

  }

  .success {
    text-align: center;

    .el-icon-circle-check {
      font-size: 55px;
      position: relative;
      margin: 40px auto 20px auto;
      color: #07C160;
    }

    .success-detail {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 24px;
    }

    .mobile {
      font-size: 15px;
      font-weight: 600;
      color: #333333;
      line-height: 21px;
      margin-bottom: 10px;
    }

    .tips {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
    }
  }

}
</style>
